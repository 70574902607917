import { useEffect, useState, forwardRef } from "react";
import { useAnimations, useFBX, useGLTF } from "@react-three/drei";

export const Ani = forwardRef((props, ref) => {
  const animations = ["ArmenianDance", "BellyDance", "Shuffle"];
  const [currentAnimation, setCurrentAnimation] = useState("Idle");
  const [audoPlaying, setAudioPlaying] = useState(false);

  const { nodes, materials } = useGLTF("ani.glb");
  const { animations: armenianDanceAnimation } = useFBX(
    "./animations/Can Can.fbx"
  );
  const { animations: bellyDanceAnimation } = useFBX(
    "./animations/Belly Dance.fbx"
  );
  const { animations: idleAnimation } = useFBX("./animations/Happy Idle.fbx");
  const { animations: musicAnimation } = useFBX(
    "./animations/Listening To Music.fbx"
  );
  const { animations: shufflingAnimation } = useFBX(
    "./animations/Shuffling.fbx"
  );

  armenianDanceAnimation[0].name = "ArmenianDance";
  bellyDanceAnimation[0].name = "BellyDance";
  idleAnimation[0].name = "Idle";
  musicAnimation[0].name = "Music";
  shufflingAnimation[0].name = "Shuffle";

  const { actions } = useAnimations(
    [
      armenianDanceAnimation[0],
      bellyDanceAnimation[0],
      idleAnimation[0],
      musicAnimation[0],
      shufflingAnimation[0],
    ],
    ref
  );

  const selectRandomAnimation = () => {
    const randomIndex = Math.floor(Math.random() * animations.length);
    // Turn music permently on.
    setAudioPlaying(true);
    setCurrentAnimation(animations[randomIndex]);
  };

  useEffect(() => {
    actions[currentAnimation].reset().fadeIn(0.5).play();

    const timeoutId = setTimeout(() => {
      setCurrentAnimation(audoPlaying ? "Music" : "Idle");
    }, 8000);
    return () => {
      clearTimeout(timeoutId);
      actions[currentAnimation].reset().fadeOut(0.5);
    };
  }, [currentAnimation]);

  useEffect(() => {
    if (audoPlaying) {
      const audio = new Audio("./audio/TechnoMusic.mpeg");
      audio.loop = true;
      audio.play();
      audio.volume = 0.05;
      return () => audio.pause();
    }
  }, [audoPlaying]);

  return (
    <group {...props} ref={ref} dispose={null}>
      <primitive object={nodes.Hips} />
      <primitive object={nodes.Wolf3D_Glasses} />
      <primitive object={nodes.Wolf3D_Hair} />
      <primitive object={nodes.EyeLeft} />
      <primitive object={nodes.EyeRight} />
      <primitive object={nodes.Wolf3D_Head} />
      <primitive object={nodes.Wolf3D_Teeth} />
      <primitive object={nodes.Wolf3D_Head} />
      <primitive object={nodes.Wolf3D_Outfit_Top} />
      <primitive object={nodes.Wolf3D_Outfit_Bottom} />
      <primitive object={nodes.Wolf3D_Outfit_Footwear} />
      <primitive object={nodes.Wolf3D_Body} />
      <mesh position-y={[1]} onClick={selectRandomAnimation}>
        <planeGeometry args={[2, 2]} />
        <meshBasicMaterial visible={false} />
      </mesh>
    </group>
  );
});

useGLTF.preload("ani.glb");

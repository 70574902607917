import {
  Text,
  ContactShadows,
  Float,
  Environment,
  PresentationControls,
} from "@react-three/drei";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Ani } from "./Ani";
import { useControls } from "leva";
import { FloatingButton } from "./FloatingButton";
import { SoundCloud, Instragram, TikTok } from "./Icons";

export default function Experience() {
  const aniRef = useRef();
  const { Background } = useControls({ Background: "#414141" });

  const soundCloudRef = useRef();
  const instragramRef = useRef();
  const tiktokRef = useRef();
  const panelRef = useRef();

  useFrame((state) => {
    panelRef.current.lookAt(state.camera.position);
  });

  return (
    <>
      <Environment preset="city" />
      <color args={[Background]} attach="background" />

      <PresentationControls
        global
        polar={[0, 0]}
        azimuth={[-0.5, 0.5]}
        config={{ mass: 2, tension: 400 }}
        snap={{ mass: 4, tension: 400 }}
      >
        <Ani ref={aniRef} position={[-1, 0, 0.8]} scale={0.7} />

        <group position={[-1.4, 0, 1.8]} ref={panelRef}>
          <group position={[-0.4, 0, 0]}>
            <FloatingButton
              url="https://soundcloud.com/a_n_i_dj"
              ref={soundCloudRef}
            >
              <SoundCloud />
            </FloatingButton>
          </group>

          <group position={[0, 0, 0]}>
            <FloatingButton
              url="https://www.instagram.com/a.n.i._official_"
              ref={instragramRef}
            >
              <Instragram />
            </FloatingButton>
          </group>

          <group position={[0.4, 0, 0]}>
            <FloatingButton
              url="https://www.tiktok.com/@a.n.i__"
              ref={tiktokRef}
            >
              <TikTok />
            </FloatingButton>
          </group>
        </group>

        <rectAreaLight
          width={2.5}
          height={1.65}
          intensity={5}
          color={"#aeddeb"}
          rotation={[-0.1, Math.PI, 0]}
          position={[0, 0.55, -1.15]}
        />

        <Float rotationIntensity={0.4}>
          <Text
            font="./bangers-v20-latin-regular.woff"
            fontSize={0.5}
            position={[2, 1.2, -1.5]}
            rotation-y={-1.25}
            maxWidth={4}
          >
            Website under Construction ...
          </Text>
        </Float>
      </PresentationControls>

      <ContactShadows position-y={0} opacity={0.4} scale={5} blur={2.4} />
    </>
  );
}

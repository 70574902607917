import { useGLTF } from "@react-three/drei";

export function SoundCloud(props) {
  const { nodes, materials } = useGLTF("/soundCloud.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005.geometry}
          material={materials.soundcloud}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005_1.geometry}
          material={materials["glossy putih.017"]}
        />
      </group>
    </group>
  );
}

export function Instragram(props) {
  const { nodes, materials } = useGLTF("/instragram.glb");
  return (
    <group {...props} dispose={null} scale={0.12}>
      <group
        rotation={[Math.PI / 2, Math.PI / 2 + 0.25, 0]}
        scale={[1, 0.092, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_4.geometry}
          material={materials.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_6.geometry}
          material={materials["Material.002"]}
          position={[0.001, 1.301, 0.002]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[0.296, 0.403, 0.296]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_8.geometry}
          material={materials["Material.002"]}
          position={[0.195, 1.193, 0.343]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[0.074, 0.233, 0.074]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_10.geometry}
          material={materials["Material.002"]}
          position={[0.004, 1.692, -0.003]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[0.533, 5.793, 0.533]}
        />
      </group>
    </group>
  );
}

export function TikTok(props) {
  const { nodes, materials } = useGLTF("/tiktok.glb");
  return (
    <group {...props} dispose={null} scale={0.12}>
      <group
        //   position={[0, 0.018, -0.064]}
        //   rotation={[-2.884, 0, -Math.PI / 2]}
        rotation={[Math.PI / 2, Math.PI / 2 + 0.25, 0]}
        scale={[1, 0.092, 1]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_4.geometry}
          material={materials.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_6.geometry}
          material={materials["Material.002"]}
          position={[-0.068, 1.46, 0.003]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[1, 10.861, 1]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_8.geometry}
          material={materials.Material}
          position={[-0.135, 1.06, 0.039]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[1, 10.861, 1]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_10.geometry}
          material={materials["Material.001"]}
          position={[-0.011, 1.06, -0.033]}
          rotation={[-Math.PI, 0.257, -Math.PI]}
          scale={[1, 10.861, 1]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/tiktok.glb");
useGLTF.preload("/soundCloud.glb");
useGLTF.preload("/instragram.glb");

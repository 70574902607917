import { forwardRef } from "react";
import { gsap } from "gsap";

export const FloatingButton = forwardRef(({ children, url }, ref) => {
  function onOver() {
    gsap.to(ref.current.position, { y: 0.1, duration: 1 });
  }

  function onOut() {
    gsap.to(ref.current.position, { y: 0, duration: 1 });
  }

  function onClick() {
    gsap.to(ref.current.rotation, {
      y: 40,
      duration: 1,
      onComplete: () => {
        gsap.to(ref.current.position, { y: 10, duration: 1 });
      },
    });

    setTimeout(() => {
      window.location.href = url;
    }, 1000);

    setTimeout(() => {
      ref.current.rotation.y = 0;
      ref.current.psotion.y = 0;
    }, 2000);
  }

  return (
    <group
      ref={ref}
      onPointerOver={onOver}
      onPointerOut={onOut}
      onClick={onClick}
    >
      {children}
    </group>
  );
});
